import * as gamesTypes from "./games.type";

const initialState = {
  luckyWheel: {},
  luckyWheelControl: null,
  luckyWheelStart: null,
  saveReward: null,
  findMate: null,
  findMateControl: null,
  findMateSave: null,
  hangman: null,
  hangmanControl: null,
  hangmanStart: null,
  checkLetters: null,
  checkOneLetter: null,
  hangmanAnswer: null,
  guessPicture: null,
  guessPictureControl: null,
  guessPictureStart: null,
  guessPictureAnswer: null,
  guessPictureOpenBox: null,
  scoreHunt: null,
  scoreHuntControl: null,
  scoreHuntStart: null,
  scoreHuntAnswer: null,
  wws: null,
  wwsControl: null,
  wwsStart: null,
  wwsSaveAnswer: null,
  wwsBonus: null,
  error: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    // Lucky Wheel
    case `${gamesTypes.GET_LUCKY_WHEEL_PENDING}`:
    case `${gamesTypes.GET_LUCKY_WHEEL_CONTROL_PENDING}`:
    case `${gamesTypes.GET_LUCKY_WHEEL_START_PENDING}`:
    case `${gamesTypes.SET_LUCKY_WHEEL_PENDING}`:

    // Find Mate
    // eslint-disable-next-line no-fallthrough
    case `${gamesTypes.GET_FIND_MATE_PENDING}`:
    case `${gamesTypes.GET_FIND_MATE_CONTROL_PENDING}`:
    case `${gamesTypes.SET_FIND_MATE_PENDING}`:

    // Hangman
    // eslint-disable-next-line no-fallthrough
    case `${gamesTypes.GET_HANGMAN_PENDING}`:
    case `${gamesTypes.GET_HANGMAN_CONTROL_PENDING}`:
    case `${gamesTypes.GET_HANGMAN_START_PENDING}`:
    case `${gamesTypes.GET_HANGMAN_CHECK_LETTERS_PENDING}`:
    case `${gamesTypes.GET_HANGMAN_CHECK_LETTER_PENDING}`:

    // Guess Picture
    // eslint-disable-next-line no-fallthrough
    case `${gamesTypes.GET_GUESS_PICTURE_PENDING}`:
    case `${gamesTypes.GET_GUESS_PICTURE_START_PENDING}`:
    case `${gamesTypes.GET_GUESS_PICTURE_CONTROL_PENDING}`:
    case `${gamesTypes.GET_GUESS_PICTURE_OPEN_BOX_PENDING}`:
    case `${gamesTypes.SET_GUESS_PICTURE_ANSWER_PENDING}`:

    // Score Hunt
    // eslint-disable-next-line no-fallthrough
    case `${gamesTypes.GET_SCORE_HUNT_PENDING}`:
    case `${gamesTypes.GET_SCORE_HUNT_START_PENDING}`:
    case `${gamesTypes.GET_SCORE_HUNT_CONTROL_PENDING}`:
    case `${gamesTypes.SET_SCORE_HUNT_ANSWER_PENDING}`:

    // Who Wants Score
    // eslint-disable-next-line no-fallthrough
    case `${gamesTypes.GET_WHO_WANTS_SCORE_PENDING}`:
    case `${gamesTypes.GET_WHO_WANTS_SCORE_CONTROL_PENDING}`:
    case `${gamesTypes.GET_WHO_WANTS_SCORE_START_PENDING}`:
    case `${gamesTypes.SET_WHO_WANTS_SCORE_ANSWER_PENDING}`:
    case `${gamesTypes.GET_WHO_WANTS_SCORE_BONUS_PENDING}`:
      return { ...state, isLoading: true, error: null };
    
    // Lucky Wheel
    case `${gamesTypes.GET_LUCKY_WHEEL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        luckyWheel: payload.data,
      };
    case `${gamesTypes.GET_LUCKY_WHEEL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        luckyWheel: [],
      };

    case `${gamesTypes.GET_LUCKY_WHEEL_CONTROL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        luckyWheelControl: payload.data,
      };
    case `${gamesTypes.GET_LUCKY_WHEEL_CONTROL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        luckyWheelControl: payload,
      };

    case `${gamesTypes.GET_LUCKY_WHEEL_START_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        luckyWheelStart: payload.data,
      };
    case `${gamesTypes.GET_LUCKY_WHEEL_START_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        luckyWheelStart: payload,
      };

    case `${gamesTypes.SET_LUCKY_WHEEL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        saveReward: payload.data,
      };
    case `${gamesTypes.SET_LUCKY_WHEEL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        saveReward: null,
      };

    // Find Mate
    case `${gamesTypes.GET_FIND_MATE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        findMate: payload.data,
      };
    case `${gamesTypes.GET_FIND_MATE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        findMate: payload,
      };

    case `${gamesTypes.GET_FIND_MATE_CONTROL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        findMateControl: payload.data,
      };
    case `${gamesTypes.GET_FIND_MATE_CONTROL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        findMateControl: payload,
      };

    case `${gamesTypes.SET_FIND_MATE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        findMateSave: payload.data,
      };
    case `${gamesTypes.SET_FIND_MATE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        findMateSave: payload,
      };

    // Hangman
    case `${gamesTypes.GET_HANGMAN_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        hangman: payload.data,
      };

    case `${gamesTypes.GET_HANGMAN_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        hangman: payload,
      };

    case `${gamesTypes.GET_HANGMAN_CONTROL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        hangmanControl: payload.data,
      };

    case `${gamesTypes.GET_HANGMAN_CONTROL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        hangmanControl: payload,
      };

    case `${gamesTypes.GET_HANGMAN_START_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        hangmanStart: payload.data,
      };

    case `${gamesTypes.GET_HANGMAN_START_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        hangmanStart: payload,
      };

    case `${gamesTypes.GET_HANGMAN_CHECK_LETTERS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        checkLetters: payload.data,
      };

    case `${gamesTypes.GET_HANGMAN_CHECK_LETTERS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        checkLetters: payload,
      };

    case `${gamesTypes.GET_HANGMAN_CHECK_LETTER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        checkOneLetter: payload.data,
      };

    case `${gamesTypes.GET_HANGMAN_CHECK_LETTER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        checkOneLetter: payload,
      };

    case `${gamesTypes.GET_HANGMAN_SAVE_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        hangmanAnswer: payload.data,
      };

    case `${gamesTypes.GET_HANGMAN_SAVE_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        hangmanAnswer: payload,
      };

    // Guess Picture
    case `${gamesTypes.GET_GUESS_PICTURE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        guessPicture: payload.data,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        guessPicture: payload,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_CONTROL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        guessPictureControl: payload.data,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_CONTROL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        guessPictureControl: payload,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_START_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        guessPictureStart: payload.data,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_START_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        guessPictureStart: payload,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_OPEN_BOX_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        guessPictureOpenBox: payload.data,
      };

    case `${gamesTypes.GET_GUESS_PICTURE_OPEN_BOX_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        guessPictureOpenBox: payload,
      };

    case `${gamesTypes.SET_GUESS_PICTURE_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        guessPictureAnswer: payload.data,
      };

    case `${gamesTypes.SET_GUESS_PICTURE_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        guessPictureAnswer: payload,
      };

    // Score Hunt
    case `${gamesTypes.GET_SCORE_HUNT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreHunt: payload.data,
      };

    case `${gamesTypes.GET_SCORE_HUNT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        scoreHunt: payload,
      };

    case `${gamesTypes.GET_SCORE_HUNT_CONTROL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreHuntControl: payload.data,
      };

    case `${gamesTypes.GET_SCORE_HUNT_CONTROL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        scoreHuntControl: payload,
      };

    case `${gamesTypes.GET_SCORE_HUNT_START_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreHuntStart: payload.data,
      };

    case `${gamesTypes.GET_SCORE_HUNT_START_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        scoreHuntStart: payload,
      };

    case `${gamesTypes.SET_SCORE_HUNT_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        scoreHuntAnswer: payload.data,
      };

    case `${gamesTypes.SET_SCORE_HUNT_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        scoreHuntAnswer: payload,
      };

    // Who Wants Score
    case `${gamesTypes.GET_WHO_WANTS_SCORE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        wws: payload.data,
      };
    case `${gamesTypes.GET_WHO_WANTS_SCORE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        wws: [],
      };

    case `${gamesTypes.GET_WHO_WANTS_SCORE_CONTROL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        wwsControl: payload.data,
      };
    case `${gamesTypes.GET_WHO_WANTS_SCORE_CONTROL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        wwsControl: payload,
      };

    case `${gamesTypes.GET_WHO_WANTS_SCORE_START_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        wwsStart: payload.data,
      };
    case `${gamesTypes.GET_WHO_WANTS_SCORE_START_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        wwsStart: payload,
      };

    case `${gamesTypes.GET_WHO_WANTS_SCORE_BONUS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        wwsBonus: payload.data,
      };

    case `${gamesTypes.GET_WHO_WANTS_SCORE_BONUS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        wwsBonus: payload,
      };

    case `${gamesTypes.SET_WHO_WANTS_SCORE_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        wwsSaveAnswer: payload.data,
      };
    case `${gamesTypes.SET_WHO_WANTS_SCORE_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        wwsSaveAnswer: null,
      };
      
    default:
      return state;
  }
};

export default reducer;
