/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cartAction,
  profileAction,
  orderAction,
  shopAction,
} from "../../redux/modules";

// Form Validations
import { Formik } from "formik";
import * as yup from "yup";

import Form from "react-bootstrap/Form";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Toastify
import showMessage from "../../components/ShowMessage";
import showErrMessage from "../../components/ShowErrMessage";
import GlobalLoader from "../../components/GlobalLoader";

// react-icons
import { FaTrashAlt, FaTrophy } from "react-icons/fa";

const Cart = () => {
  const dispatch = useDispatch();
  const loginSettings = useSelector((store) => store.auth.loginSettings);
  let cartData = useSelector((store) => store.cart.cartData);
  const profileDetail = useSelector((store) => store.profile.profileDetail);
  const addressData = useSelector((store) => store.profile.address);
  const isLoading = useSelector((store) => store.order.isLoading);
  const scoreCurrency = useSelector((store) => store.order.scoreCurrency);
  const paymentModule = useSelector((store) => store.cart.paymentModule);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [validatedForm, setValidatedForm] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderSuccessTimer, setOrderSuccessTimer] = useState(false);

  const schema = yup.object({
    address: yup.string().required("Lütfen Adres Seçiniz!"),
    agreement: yup
      .bool()
      .required()
      .oneOf([true], "Şartlar ve koşullar sözleşmelerini kabul etmek zorundasınız!"),
  });

  useEffect(() => {
    dispatch(cartAction.getCart());
    dispatch(profileAction.getAddress());
    dispatch(orderAction.getScoreCurrency("ORDER"));
    dispatch(cartAction.getPaymentModule("SCORE_CURRENCY_CONVERSION"));

    return () => {};
  }, []);

  useEffect(() => {
    if (addressData && addressData.length > 0) {
      const filteredData = addressData
        .filter((address) => address.orderAddress === true)
        .map((filteredAddress) => filteredAddress.addressId);

      setSelectedAddress(filteredData[0]);
    }

    return () => {};
  }, [addressData]);

  const handleSubmitOrder = (values) => {
    // Sepeti Güncelleme gerekli + - kullanılarak ürün sayıları değiştirildi ama sepet güncellenmedi.
    //dispatch( cartAction.addToCart() );
    if (remainingAmount < 0) {
      dispatch(
        orderAction.confirmPayment(
          "EMPLOYEE",
          "KAMPANYA",
          selectedAddress,
          paymentAmount,
          profileDetail.id
        )
      )
        .then((e) => {
          if (e.isHttpSuccess) {
            showMessage(
              "Siparişiniz başarıyla alındı. Profil Siparişlerim sayfasından takip edebilirsiniz!"
            );
            setOrderSuccess(true);

            setTimeout(() => {
              setOrderSuccessTimer(true);
            }, 4000);
          }
        })
        .catch((err) => {
          if (err.error.response.data.errors) {
            err.error.response.data.errors.forEach((item) => {
              showErrMessage(item.message);
            });
          }
        });
    } else {
      dispatch(
        orderAction.createOrder(
          "EMPLOYEE",
          "KAMPANYA",
          selectedAddress,
          "test",
          false
        )
      )
        .then((e) => {
          if (e.isHttpSuccess) {
            showMessage(
              "Siparişiniz başarıyla alındı. Profil Siparişlerim sayfasından takip edebilirsiniz!"
            );
            setOrderSuccess(true);

            setTimeout(() => {
              setOrderSuccessTimer(true);
            }, 4000);
          }
        })
        .catch((err) => {
          if (err.error.response.data.errors) {
            err.error.response.data.errors.forEach((item) => {
              showErrMessage(item.message);
            });
          }
        });
    }
    setValidatedForm(true);
  };

  const handleRemoveCartItem = (e, id) => {
    if (e !== null) {
      e.preventDefault();
    }

    dispatch(cartAction.removeCartItemById(id))
      .then((response) => {
        if (response.isHttpSuccess) {
          showMessage(`Ürün sepetten kaldırıldı!`);
          setTimeout(() => {
            dispatch(cartAction.getCart());
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.isHttpError) {
          showErrMessage(`Üzgünüz. Ürün sepetten kaldırılamadı!`);
        }

        console.log("err: ", err);
      });
  };

  const handleMinus = (item) => {
    if (item.productCount > 1) {
      dispatch(shopAction.addToCart(item.product.id, item.productCount - 1))
        .then((response) => {
          if (response.isHttpSuccess) {
            dispatch(cartAction.getCart());
          }
        })
        .catch((err) => {
          showErrMessage(err.message);
        });
    } else {
      handleRemoveCartItem(null, item.id);
    }
  };

  const handlePlus = (id, productCount, stock) => {
    if (productCount < stock) {
      dispatch(shopAction.addToCart(id, productCount + 1))
        .then((response) => {
          if (response.isHttpSuccess) {
            dispatch(cartAction.getCart());
          }
        })
        .catch((err) => {
          showErrMessage(err.message);
        });
    }
  };

  const handleRemoveAllItems = () => {
    dispatch(cartAction.removeAllItems())
      .then((response) => {
        if (response.isHttpSuccess) {
          showMessage(`Tüm ürünler sepetten kaldırıldı!`);
          dispatch(cartAction.getCart());
        }
      })
      .catch((err) => {
        if (err.isHttpError) {
          showErrMessage(`Üzgünüz. Tüm ürünler sepetten kaldırılamadı!`);
        }
      });
  };

  const remainingAmount = profileDetail?.score - cartData?.totalScore;
  const paymentAmount = scoreCurrency * Math.abs(remainingAmount);

  return (
    <>
      <Header />

      <main>
        <section className="bg-blue py-5 mb-5">
          <div className="container">
            <h1 className="text-white mb-0 text-center">Sipariş Oluştur</h1>
          </div>
        </section>

        {cartData && cartData.cartProductDTOList.length > 0 ? (
          <section className="my-5">
            {orderSuccess ? (
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-7 col-md-10">
                    <div className="bg-gray-lightest rounded-3 p-5 text-center mb-5">
                      <h3 className="mb-5 text-green">
                        🎉 Siparişiniz Alındı!
                      </h3>
                      <p className="lead mb-5">
                        Hediyeniz <strong>15 iş günü</strong> içerisinde kargo
                        aracılığıyla size ulaşacaktır. İyi Günlerde Kullanın!
                      </p>
                      <Link
                        to="/profile/orders"
                        className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                      >
                        Siparişlerim
                      </Link>
                    </div>
                    <p className="small fw-light opacity-75 text-center">
                      Siparişiniz ile ilgili sorularınız için Sıkça Sorulan
                      Sorular bölümünden yardım alabilir, Connection Çağrı
                      Merkezi'ni 0850 532 5787 nolu telefondan Pazartesi ila
                      Cuma günleri 09:00-18:00 saatleri arasında arayabilir veya
                      Bize Ulaşın'dan bize erişebilirsiniz.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <Formik
                validationSchema={schema}
                onSubmit={(values, { resetForm }) =>
                  handleSubmitOrder(values, { resetForm })
                }
                initialValues={{
                  address: "",
                  agreement: false,
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form
                    className="form-address"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="container">
                      <div className="row justify-content-center gx-5 gy-5">
                        <div className="col-xl-8 col-lg-8">
                          <h5 className="mb-4 text-blue uppercase">Sepetim</h5>
                          {cartData && cartData.cartProductDTOList.length > 0
                            ? cartData.cartProductDTOList.map((item) => {
                                return (
                                  <div
                                    className="d-flex align-items-center border-bottom pb-5 mb-5"
                                    key={item.id}
                                  >
                                    {item?.product?.productContentUrlList &&
                                    item?.product?.productContentUrlList.length >
                                      0 ? (
                                      <img
                                        src={
                                          item?.product?.productContentUrlList[0]
                                            .baseAddress
                                        }
                                        alt=""
                                        className="img-contain rounded-3 shadow-lg"
                                        style={{ width: "200px", height: "150px" }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          height: "150px",
                                          background: "#000",
                                        }}
                                      >
                                        {loginSettings?.logo && (
                                          <img
                                            src={loginSettings?.logo}
                                            alt=""
                                            className="w-100 opacity-50"
                                            style={{
                                              height: "150px",
                                              objectFit: "scale-down",
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}

                                    <div className="ms-4 d-flex w-100 flex-column">
                                      <h6>{item.product.name}</h6>
                                      {item.product.description ? (
                                        <p className="mt-3 mb-0 fw-light opacity-75">
                                          {item.product.description}
                                        </p>
                                      ) : null}

                                      <div>
                                        <div className="shopping-cart-point my-2 d-flex align-items-center w-100">
                                          <FaTrophy className="d-inline-block me-2 text-green" />
                                          <span className="d-inline-block">
                                            {item.product.score}
                                          </span>
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                              fontSize: "20px",
                                            }}
                                            className="cursor-pointer text-red"
                                          >
                                            <FaTrashAlt
                                              className="text-red"
                                              onClick={(e) =>
                                                handleRemoveCartItem(e, item.id)
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                          <button
                                            type="button"
                                            style={{ fontSize: "24px" }}
                                            className="btn btn-outline-secondary btn-sm me-2 "
                                            onClick={() => handleMinus(item)}
                                          >
                                            -
                                          </button>
                                          <span
                                            style={{ fontSize: "20px" }}
                                            className="me-2"
                                          >
                                            {item.productCount}
                                          </span>
                                          <button
                                            type="button"
                                            style={{ fontSize: "24px" }}
                                            className="btn btn-outline-secondary btn-sm me-2"
                                            onClick={() =>
                                              handlePlus(
                                                item.product.id,
                                                item.productCount,
                                                item.product.stock
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}

                            
                          <Form.Group controlId="changePasswordFormValidation">
                            <h5 className="mb-4 d-flex justify-content-between align-self-center">
                              <span className="text-blue">Teslimat Adresi</span>
                              <Link to="profile/address" className="fs-6 text-green">
                                <small>Yeni Adres Ekle</small>
                              </Link>
                            </h5>

                            <div className="row gy-3 row-cols-md-1 row-cols-1 align-items-start">  
                              <div className="col">
                                <Form.Control
                                  as="select"
                                  defaultValue=""
                                  className="form-control"
                                  autoComplete="chrome-off"
                                  placeholder="Teslimat Adresi Seçiniz"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="address"
                                  value={values.address}
                                  isInvalid={
                                    !!errors.address &&
                                    touched.address &&
                                    !!errors.address
                                  }
                                >
                                  <option value="">Seçiniz</option>
                                  {addressData?.map((item, i) => (
                                    <option value={item.addressId} key={i}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Form.Control>

                                <Form.Control.Feedback className="FeedBack" type="invalid">
                                  {errors.address &&
                                    touched.address &&
                                    errors.address}
                                </Form.Control.Feedback>
                              </div>

                              <Form.Group>
                                <Form.Label></Form.Label>
                                <Form.Check
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="agreement"
                                  value={values.agreement}
                                  label="Aşağıdaki şart ve koşulları okudum, onaylıyorum."
                                  isInvalid={
                                    !!errors.agreement &&
                                    touched.agreement &&
                                    !!errors.agreement
                                  }
                                  id="agreement"
                                />
                              
                                <div
                                  className="fw-light mt-4"
                                  style={{ fontSize: "14px", height: "150px", overflowY: "scroll" }}
                                >
                                  <p>
                                    Gerçek ürün ile resimdeki ürün arasında renk, boy,
                                    şekil, baskı ve benzeri fiziksel özelliklerde
                                    farklılık olabilir. "Siparişi Onayla" butonuna
                                    tıklamadan önce tüm detayları kontrol etmelisiniz
                                    çünkü onaylamış olduğunuz siparişler değiştirilemez,
                                    iptal edilemez, puan iadesi yapılamaz. Connection’dan
                                    siparişini vermiş olduğunuz ürünler, sipariş
                                    verildikten sonra on beş (15) iş günü (resmi tatiller
                                    ve bayram tatilleri, ile hafta sonu günleri iş
                                    günlerine dahil değildir) içinde sistemde kayıtlı
                                    bulunan ve sipariş adresi olarak seçtiğiniz adresinize
                                    teslim edilecektir. Siparişiniz için kargo ücreti
                                    ödemezsiniz. Ürün teslim alınırken, ürünün doğru,
                                    sağlam ve eksiksiz olduğunu kontrol etmek ve öyle
                                    teslim almak, teslim alan kişinin sorumluluğundadır.
                                    Kontrol sırasında, hasarlı ürün tespit edilirse
                                    siparişinizi teslim eden kargo firması ile birlikte
                                    tutanak tutmanız gerekmektedir. Tutanak tutulduktan
                                    sonra 0850 532 5787 (JTI) numaralı Çağrı Merkezi’mizi
                                    arayarak iade sürecini başlatabilirsiniz. Tutanak
                                    tutulmaması durumunda tarafımıza yapılacak hasarlı
                                    ürün iadeleri ve itirazlar kabul edilmeyecektir. JTI
                                    gerekli gördüğü durumlarda Connection Dükkanı’nda yer
                                    alan ürünlerin puanlarını değiştirme veya dilediği
                                    ürünü Connection Dükkanı’ndan kaldırma hakkına
                                    sahiptir. Sitede yer alan ürünler tedarikçi stokları
                                    ile sınırlıdır. Stoğu tükenmiş ürünler için Kullanıcı
                                    hiçbir hak iddiasında bulunamaz. JTI, Connection
                                    Dükkanı’nda yer alan ürünlerin tedariğinde sorun
                                    çıkması durumunda, ürünlerin muadilini veya bir üst
                                    modelini gönderebilir. Kullanıcı, kendisine gönderilen
                                    ürünün Connection Dükkanı’nda sergilenen ürün
                                    olmadığına dair JTI’dan hak talep edemez. 25 Mayıs -
                                    13 Aralık 2020 tarihleri arasında sürecek olan
                                    Connection'ın 2020 dönemi boyunca Connection
                                    Dükkanı’ndaki hediyelerden en fazla 2 adet sipariş
                                    verebilirsiniz. Bu dönemde kazandığınız puanlarınızı
                                    13 Aralık 2020 günü saat 23:59'a kadar
                                    harcayabilirsiniz. 13 Aralık 2020 günü saat 23:59
                                    itibariyle kalan puanlar silinecektir. ‘’Siparişi
                                    Onayla’’ butonuna tıkladığınızda burada yazılı olan
                                    şartları kabul etmiş sayılacaksınız.
                                  </p>
                                </div>
                              </Form.Group>
                            </div>
                          </Form.Group>


                          {/* <h5 className="mb-4 d-flex justify-content-between align-self-center">
                            <span className="text-blue">Teslimat Adresi</span>
                            <Link to="profile/address" className="fs-6 text-green">
                              <small>Yeni Adres Ekle</small>
                            </Link>
                          </h5>
                          <div className="border-bottom pb-5 mb-5">
                            <div className="form-floating">
                              <select
                                className="form-select"
                                name="address"
                                required
                              >
                                <option value="">Seçiniz</option>
                                {addressData?.map((item, i) => (
                                  <option value={item.addressId} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              <label>Adres</label>
                            </div>
                          </div> */}

                          {/* <div className="form-check my-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="confirm"
                              required
                            />
                            <label
                              className="form-check-label small"
                              htmlFor="confirm"
                            >
                              Aşağıdaki şart ve koşulları okudum, onaylıyorum.
                            </label>
                          </div> */}
                          
                          
                          {/* <div
                            className="fw-light overflow-scroll"
                            style={{ fontSize: "14px", height: "150px" }}
                          >
                            <p>
                              Gerçek ürün ile resimdeki ürün arasında renk, boy,
                              şekil, baskı ve benzeri fiziksel özelliklerde
                              farklılık olabilir. "Siparişi Onayla" butonuna
                              tıklamadan önce tüm detayları kontrol etmelisiniz
                              çünkü onaylamış olduğunuz siparişler değiştirilemez,
                              iptal edilemez, puan iadesi yapılamaz. Connection’dan
                              siparişini vermiş olduğunuz ürünler, sipariş
                              verildikten sonra on beş (15) iş günü (resmi tatiller
                              ve bayram tatilleri, ile hafta sonu günleri iş
                              günlerine dahil değildir) içinde sistemde kayıtlı
                              bulunan ve sipariş adresi olarak seçtiğiniz adresinize
                              teslim edilecektir. Siparişiniz için kargo ücreti
                              ödemezsiniz. Ürün teslim alınırken, ürünün doğru,
                              sağlam ve eksiksiz olduğunu kontrol etmek ve öyle
                              teslim almak, teslim alan kişinin sorumluluğundadır.
                              Kontrol sırasında, hasarlı ürün tespit edilirse
                              siparişinizi teslim eden kargo firması ile birlikte
                              tutanak tutmanız gerekmektedir. Tutanak tutulduktan
                              sonra 0850 532 5787 (JTI) numaralı Çağrı Merkezi’mizi
                              arayarak iade sürecini başlatabilirsiniz. Tutanak
                              tutulmaması durumunda tarafımıza yapılacak hasarlı
                              ürün iadeleri ve itirazlar kabul edilmeyecektir. JTI
                              gerekli gördüğü durumlarda Connection Dükkanı’nda yer
                              alan ürünlerin puanlarını değiştirme veya dilediği
                              ürünü Connection Dükkanı’ndan kaldırma hakkına
                              sahiptir. Sitede yer alan ürünler tedarikçi stokları
                              ile sınırlıdır. Stoğu tükenmiş ürünler için Kullanıcı
                              hiçbir hak iddiasında bulunamaz. JTI, Connection
                              Dükkanı’nda yer alan ürünlerin tedariğinde sorun
                              çıkması durumunda, ürünlerin muadilini veya bir üst
                              modelini gönderebilir. Kullanıcı, kendisine gönderilen
                              ürünün Connection Dükkanı’nda sergilenen ürün
                              olmadığına dair JTI’dan hak talep edemez. 25 Mayıs -
                              13 Aralık 2020 tarihleri arasında sürecek olan
                              Connection'ın 2020 dönemi boyunca Connection
                              Dükkanı’ndaki hediyelerden en fazla 2 adet sipariş
                              verebilirsiniz. Bu dönemde kazandığınız puanlarınızı
                              13 Aralık 2020 günü saat 23:59'a kadar
                              harcayabilirsiniz. 13 Aralık 2020 günü saat 23:59
                              itibariyle kalan puanlar silinecektir. ‘’Siparişi
                              Onayla’’ butonuna tıkladığınızda burada yazılı olan
                              şartları kabul etmiş sayılacaksınız.
                            </p>
                          </div> */}

                        </div>

                        <div className="col-xl-4 col-lg-4">
                          <div className="bg-gray-lightest rounded-3 p-4">
                            <h5 className="mb-4">Sipariş Özeti</h5>
                            <div className="d-flex justify-content-between border-bottom py-3 small">
                              <div>Mevcut Puanınız</div>
                              <div>
                                {profileDetail?.score >= 1000
                                  ? profileDetail?.score.toLocaleString("tr-TR")
                                  : profileDetail?.score}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between border-bottom py-3 small">
                              <div>Sipariş Tutarı (Puan)</div>
                              <div>
                                {cartData?.totalScore >= 1000
                                  ? cartData?.totalScore.toLocaleString("tr-TR")
                                  : cartData?.totalScore}
                              </div>
                            </div>

                            {remainingAmount < 0 && paymentModule && (
                              <div className="d-flex justify-content-between py-3 small text-blue">
                                <div>Kartınızdan tahsil edilecek tutar</div>
                                <div>
                                  {paymentAmount >= 1000
                                    ? paymentAmount.toLocaleString("tr-TR", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : paymentAmount.toFixed(2)}{" "}
                                  TL
                                </div>
                              </div>
                            )}

                            {remainingAmount < 0 && paymentModule && (
                              <button
                                type="submit"
                                className="btn btn-blue text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 mt-4 w-100"
                              >
                                Ödemeye Geç
                              </button>
                            )}
                            {remainingAmount > 0 && (
                              <button
                                type="submit"
                                className="btn btn-green text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3 mt-4 w-100"
                              >
                                Siparişi Tamamla
                              </button>
                            )}
                            <button
                              className="btn btn-red text-white rounded-pill w-100 mt-2"
                              onClick={handleRemoveAllItems}
                            >
                              Tüm Ürünleri Kaldır
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Form>
                  )}
                </Formik>
            )}
          </section>
        ) : (
          <section className="my-sm-10 my-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-md-10">
                  <div className="bg-gray-lightest rounded-3 p-5 text-center mb-5">
                    <h3 className="mb-5 text-red">🛒 Sepetiniz Boş!</h3>
                    <p className="lead mb-5">
                      Şu anda sepetinizde ürün bulunmamaktadır. Alışverişe devam
                      etmek için mağazamızı ziyaret edebilirsiniz.
                    </p>
                    <Link
                      to="/shop"
                      className="btn btn-blue text-white rounded-pill py-sm-3 py-2 px-sm-4 px-3"
                    >
                      Alışverişe Devam Et
                    </Link>
                  </div>
                  <p className="small fw-light opacity-75 text-center">
                    Siparişiniz ile ilgili sorularınız için Sıkça Sorulan
                    Sorular bölümünden yardım alabilir, Connection Çağrı
                    Merkezi'ni 0850 532 5787 nolu telefondan Pazartesi ila Cuma
                    günleri 09:00-18:00 saatleri arasında arayabilir veya Bize
                    Ulaşın'dan bize erişebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>

      <Footer />
      <GlobalLoader isLoading={isLoading} />
    </>
  );
};

export default Cart;
