// Lucky Wheel
export const GET_LUCKY_WHEEL_PENDING = "GET_LUCKY_WHEEL_PENDING";
export const GET_LUCKY_WHEEL_FULFILLED = "GET_LUCKY_WHEEL_FULFILLED";
export const GET_LUCKY_WHEEL_REJECTED = "GET_LUCKY_WHEEL_REJECTED";

// Lucky Wheel Control
export const GET_LUCKY_WHEEL_CONTROL_PENDING =
  "GET_LUCKY_WHEEL_CONTROL_PENDING";
export const GET_LUCKY_WHEEL_CONTROL_FULFILLED =
  "GET_LUCKY_WHEEL_CONTROL_FULFILLED";
export const GET_LUCKY_WHEEL_CONTROL_REJECTED =
  "GET_LUCKY_WHEEL_CONTROL_REJECTED";

// Lucky Wheel Start
export const GET_LUCKY_WHEEL_START_PENDING = "GET_LUCKY_WHEEL_START_PENDING";
export const GET_LUCKY_WHEEL_START_FULFILLED =
  "GET_LUCKY_WHEEL_START_FULFILLED";
export const GET_LUCKY_WHEEL_START_REJECTED = "GET_LUCKY_WHEEL_START_REJECTED";

// Lucky Wheel Set
export const SET_LUCKY_WHEEL_PENDING = "SET_LUCKY_WHEEL_PENDING";
export const SET_LUCKY_WHEEL_FULFILLED = "SET_LUCKY_WHEEL_FULFILLED";
export const SET_LUCKY_WHEEL_REJECTED = "SET_LUCKY_WHEEL_REJECTED";

// Find Mate
export const GET_FIND_MATE_PENDING = "GET_FIND_MATE_PENDING";
export const GET_FIND_MATE_FULFILLED = "GET_FIND_MATE_FULFILLED";
export const GET_FIND_MATE_REJECTED = "GET_FIND_MATE_REJECTED";

// Find Mate Control
export const GET_FIND_MATE_CONTROL_PENDING = "GET_FIND_MATE_CONTROL_PENDING";
export const GET_FIND_MATE_CONTROL_FULFILLED =
  "GET_FIND_MATE_CONTROL_FULFILLED";
export const GET_FIND_MATE_CONTROL_REJECTED = "GET_FIND_MATE_CONTROL_REJECTED";

// Find Mate Set
export const SET_FIND_MATE_PENDING = "SET_FIND_MATE_PENDING";
export const SET_FIND_MATE_FULFILLED = "SET_FIND_MATE_FULFILLED";
export const SET_FIND_MATE_REJECTED = "SET_FIND_MATE_REJECTED";

// Hangman
export const GET_HANGMAN_PENDING = "GET_HANGMAN_PENDING";
export const GET_HANGMAN_FULFILLED = "GET_HANGMAN_FULFILLED";
export const GET_HANGMAN_REJECTED = "GET_HANGMAN_REJECTED";

// Hangman Control
export const GET_HANGMAN_CONTROL_PENDING = "GET_HANGMAN_CONTROL_PENDING";
export const GET_HANGMAN_CONTROL_FULFILLED = "GET_HANGMAN_CONTROL_FULFILLED";
export const GET_HANGMAN_CONTROL_REJECTED = "GET_HANGMAN_CONTROL_REJECTED";

// Hangman Start
export const GET_HANGMAN_START_PENDING = "GET_HANGMAN_START_PENDING";
export const GET_HANGMAN_START_FULFILLED = "GET_HANGMAN_START_FULFILLED";
export const GET_HANGMAN_START_REJECTED = "GET_HANGMAN_START_REJECTED";

// Hangman Check Letters
export const GET_HANGMAN_CHECK_LETTERS_PENDING =
  "GET_HANGMAN_CHECK_LETTERS_PENDING";
export const GET_HANGMAN_CHECK_LETTERS_FULFILLED =
  "GET_HANGMAN_CHECK_LETTERS_FULFILLED";
export const GET_HANGMAN_CHECK_LETTERS_REJECTED =
  "GET_HANGMAN_CHECK_LETTERS_REJECTED";

// Hangman Check Letter - One Letter
export const GET_HANGMAN_CHECK_LETTER_PENDING =
  "GET_HANGMAN_CHECK_LETTER_PENDING";
export const GET_HANGMAN_CHECK_LETTER_FULFILLED =
  "GET_HANGMAN_CHECK_LETTER_FULFILLED";
export const GET_HANGMAN_CHECK_LETTER_REJECTED =
  "GET_HANGMAN_CHECK_LETTER_REJECTED";

// Hangman Save Answer
export const GET_HANGMAN_SAVE_ANSWER_PENDING =
  "GET_HANGMAN_SAVE_ANSWER_PENDING";
export const GET_HANGMAN_SAVE_ANSWER_FULFILLED =
  "GET_HANGMAN_SAVE_ANSWER_FULFILLED";
export const GET_HANGMAN_SAVE_ANSWER_REJECTED =
  "GET_HANGMAN_SAVE_ANSWER_REJECTED";

// Guess Picture
export const GET_GUESS_PICTURE_PENDING = "GET_GUESS_PICTURE_PENDING";
export const GET_GUESS_PICTURE_FULFILLED = "GET_GUESS_PICTURE_FULFILLED";
export const GET_GUESS_PICTURE_REJECTED = "GET_GUESS_PICTURE_REJECTED";

// Guess Picture Control
export const GET_GUESS_PICTURE_CONTROL_PENDING =
  "GET_GUESS_PICTURE_CONTROL_PENDING";
export const GET_GUESS_PICTURE_CONTROL_FULFILLED =
  "GET_GUESS_PICTURE_CONTROL_FULFILLED";
export const GET_GUESS_PICTURE_CONTROL_REJECTED =
  "GET_GUESS_PICTURE_CONTROL_REJECTED";

// Guess Picture Start
export const GET_GUESS_PICTURE_START_PENDING =
  "GET_GUESS_PICTURE_START_PENDING";
export const GET_GUESS_PICTURE_START_FULFILLED =
  "GET_GUESS_PICTURE_START_FULFILLED";
export const GET_GUESS_PICTURE_START_REJECTED =
  "GET_GUESS_PICTURE_START_REJECTED";

// Guess Open Box
export const GET_GUESS_PICTURE_OPEN_BOX_PENDING =
  "GET_GUESS_PICTURE_OPEN_BOX_PENDING";
export const GET_GUESS_PICTURE_OPEN_BOX_FULFILLED =
  "GET_GUESS_PICTURE_OPEN_BOX_FULFILLED";
export const GET_GUESS_PICTURE_OPEN_BOX_REJECTED =
  "GET_GUESS_PICTURE_OPEN_BOX_REJECTED";

// Guess Picture Answer
export const SET_GUESS_PICTURE_ANSWER_PENDING =
  "SET_GUESS_PICTURE_ANSWER_PENDING";
export const SET_GUESS_PICTURE_ANSWER_FULFILLED =
  "SET_GUESS_PICTURE_ANSWER_FULFILLED";
export const SET_GUESS_PICTURE_ANSWER_REJECTED =
  "SET_GUESS_PICTURE_ANSWER_REJECTED";

// Score Hunt
export const GET_SCORE_HUNT_PENDING = "GET_SCORE_HUNT_PENDING";
export const GET_SCORE_HUNT_FULFILLED = "GET_SCORE_HUNT_FULFILLED";
export const GET_SCORE_HUNT_REJECTED = "GET_SCORE_HUNT_REJECTED";

// Score Hunt Control
export const GET_SCORE_HUNT_CONTROL_PENDING = "GET_SCORE_HUNT_CONTROL_PENDING";
export const GET_SCORE_HUNT_CONTROL_FULFILLED =
  "GET_SCORE_HUNT_CONTROL_FULFILLED";
export const GET_SCORE_HUNT_CONTROL_REJECTED =
  "GET_SCORE_HUNT_CONTROL_REJECTED";

// Score Hunt Start
export const GET_SCORE_HUNT_START_PENDING = "GET_SCORE_HUNT_START_PENDING";
export const GET_SCORE_HUNT_START_FULFILLED = "GET_SCORE_HUNT_START_FULFILLED";
export const GET_SCORE_HUNT_START_REJECTED = "GET_SCORE_HUNT_START_REJECTED";

// Score Hunt Answer
export const SET_SCORE_HUNT_ANSWER_PENDING = "SET_SCORE_HUNT_ANSWER_PENDING";
export const SET_SCORE_HUNT_ANSWER_FULFILLED =
  "SET_SCORE_HUNT_ANSWER_FULFILLED";
export const SET_SCORE_HUNT_ANSWER_REJECTED = "SET_SCORE_HUNT_ANSWER_REJECTED";

// Who Wants Score
export const GET_WHO_WANTS_SCORE_PENDING = "GET_WHO_WANTS_SCORE_PENDING";
export const GET_WHO_WANTS_SCORE_FULFILLED = "GET_WHO_WANTS_SCORE_FULFILLED";
export const GET_WHO_WANTS_SCORE_REJECTED = "GET_WHO_WANTS_SCORE_REJECTED";

// Who Wants Score Control
export const GET_WHO_WANTS_SCORE_CONTROL_PENDING =
  "GET_WHO_WANTS_SCORE_CONTROL_PENDING";
export const GET_WHO_WANTS_SCORE_CONTROL_FULFILLED =
  "GET_WHO_WANTS_SCORE_CONTROL_FULFILLED";
export const GET_WHO_WANTS_SCORE_CONTROL_REJECTED =
  "GET_WHO_WANTS_SCORE_CONTROL_REJECTED";

// Who Wants Score Start
export const GET_WHO_WANTS_SCORE_START_PENDING =
  "GET_WHO_WANTS_SCORE_START_PENDING";
export const GET_WHO_WANTS_SCORE_START_FULFILLED =
  "GET_WHO_WANTS_SCORE_START_FULFILLED";
export const GET_WHO_WANTS_SCORE_START_REJECTED =
  "GET_WHO_WANTS_SCORE_START_REJECTED";

// Who Wants Score Bonus
export const GET_WHO_WANTS_SCORE_BONUS_PENDING =
  "GET_WHO_WANTS_SCORE_BONUS_PENDING";
export const GET_WHO_WANTS_SCORE_BONUS_FULFILLED =
  "GET_WHO_WANTS_SCORE_BONUS_FULFILLED";
export const GET_WHO_WANTS_SCORE_BONUS_REJECTED =
  "GET_WHO_WANTS_SCORE_BONUS_REJECTED";

// Who Wants Score Answer
export const SET_WHO_WANTS_SCORE_ANSWER_PENDING =
  "SET_WHO_WANTS_SCORE_ANSWER_PENDING";
export const SET_WHO_WANTS_SCORE_ANSWER_FULFILLED =
  "SET_WHO_WANTS_SCORE_ANSWER_FULFILLED";
export const SET_WHO_WANTS_SCORE_ANSWER_REJECTED =
  "SET_WHO_WANTS_SCORE_ANSWER_REJECTED";