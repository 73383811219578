import React from "react";
import style from "./modal.module.scss";
import { FaTimes } from "react-icons/fa";

const Modal = ({
  isOpen,
  onClose,
  children,
  showCloseButton = false,
  showCloseIconButton = true,
}) => {
  if (!isOpen) return null;

  return (
    <div className={style.modalOverlay}>
      <div className={style.modal}>
        {showCloseIconButton && (
          <div className={style.closeIconButton} onClick={onClose}>
            <FaTimes />
          </div>
        )}
        <div className={style.modalContent}>{children}</div>
        {showCloseButton && (
          <button className={style.modalCloseButton} onClick={onClose}>
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
