/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction, academyAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Styles
import "./styles.scss";

// Format Date
import moment from "moment";

const Academy = () => {
  const dispatch = useDispatch();
  const loginSettings = useSelector((store) => store.auth.loginSettings);
  // const pageDescription = useSelector((store) => store.profile.pageDescription);

  const academyList = useSelector((store) => store.academy.academyList);
  const academyCategoryList = useSelector(
    (store) => store.academy.academyCategoryList
  );

  const [selectedCategory, setSelectedCategory] = useState("0");

  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
    }

    dispatch(academyAction.getAcademyList());
    dispatch(academyAction.getAcademyCategoryList());

    return () => {};
  }, [dispatch]);

  const handleCategoryFilter = (e, category) => {
    e.preventDefault();

    // API CALL
    if (category === "0") {
      setSelectedCategory(category);
      dispatch(academyAction.getAcademyList());
    } else if (category?.id) {
      setSelectedCategory(category);
      dispatch(academyAction.getAcademyCategoryFilterById(category.id));
    }
  };

  return (
    <div className="page-container">
      <Header />

      <main>
        <section className="bg-blue py-5 mb-sm-5 mb-3">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">
                Anasayfa
              </NavLink>
              <span className="me-3">»</span>
              {selectedCategory?.categoryName ? (
                <button
                  className="bg-transparent border-0 text-white text-decoration-none me-3"
                  onClick={(e) => handleCategoryFilter(e, "0")}
                >
                  Akademi
                </button>
              ) : (
                <span className="text-white text-decoration-none me-3">
                  Akademi
                </span>
              )}
              {selectedCategory?.categoryName && (
                <>
                  <span className="me-3">»</span>
                  <span className="text-white text-decoration-none me-3">
                    {selectedCategory?.categoryName}
                  </span>
                </>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h1 className="mb-0 text-white">Akademi</h1>
            </div>
          </div>
        </section>

        <section className="mb-sm-10 mb-5">
          <div className="container">
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4">
                <div className="pe-lg-4 pe-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Kategoriler</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <ul className="category-list">
                        <li
                          className={selectedCategory === "0" ? "active" : ""}
                        >
                          <a
                            href="#"
                            onClick={(e) => handleCategoryFilter(e, "0")}
                          >
                            Tümü
                          </a>
                        </li>

                        {academyCategoryList && academyCategoryList.length > 0
                          ? academyCategoryList.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    item.id === selectedCategory?.id
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      handleCategoryFilter(e, item)
                                    }
                                  >
                                    {item.categoryName}
                                  </a>
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>

                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-filter"></i>
                  </a>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8">
                <div className="row g-sm-4 g-2">
                  {academyList?.length > 0 &&
                    academyList.map((item) => {
                      return (
                        <div
                          className="col-xl-4 col-6"
                          key={item.id}
                          id={item.id}
                        >
                          <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                            {item.url && item.topicName ? (
                              <img
                                src={item.url}
                                alt={item.topicName}
                                className="img-contain w-100"
                                style={{ height: "200px" }}
                              />
                            ) : (
                              <div
                                style={{ height: "200px", background: "#000" }}
                              >
                                {loginSettings?.logo && (
                                  <img
                                    src={loginSettings?.logo}
                                    alt=""
                                    className="w-100 opacity-50"
                                    style={{
                                      height: "200px",
                                      objectFit: "scale-down",
                                    }}
                                  />
                                )}
                              </div>
                            )}

                            <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                              {item.topicName && (
                                <h5 className="fw-normal lh-base mb-2">
                                  {item.topicName}
                                </h5>
                              )}

                              <div className="d-flex justify-content-between academy-time mb-3">
                                {(item.createDate !== null ||
                                  item.createDate !== undefined) && (
                                  <span className="d-block small text-blue mb-4">
                                    {moment(item.createDate).format(
                                      "DD.MM.YYYY"
                                    )}
                                  </span>
                                )}
                              </div>

                              <Link
                                // to={`/academy/${item.id}`}
                                to={'#'}
                                className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto add-to-cart-default"
                              >
                                <span>İncele</span>
                                <i className="fas fa-arrow-right ms-2 d-sm-inline-block d-none"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Academy;
